<template>
  <div class="register-container">
    <div class="register-image">
      <v-iconFontSymbol
        :icon="'iconlogo_blue'"
        :style="{ 'font-size': '2rem', height: '110px' }"
      />
    </div>
    <div class="register-form">
      <van-field
        v-model="state.telephone"
        type="tel"
        placeholder="请输入手机号"
        center
        maxlength="11"
      >
        <template #left-icon>
          <v-iconFontSymbol
            :icon="'iconphone_number'"
            :style="{ 'font-size': '0.15rem' }"
          />
        </template>
      </van-field>
      <div class="phone-container">
        <van-field
          v-model="state.mess_code"
          type="tel"
          center
          clearable
          placeholder="请输入短信验证码"
        >
          <template #left-icon>
            <v-iconFontSymbol
              :icon="'iconpassword'"
              :style="{ 'font-size': '0.15rem' }"
            />
          </template>
        </van-field>
        <div class="button-container-message">
          <div
            class="button-message"
            @click="getMessageCode"
            v-show="state.show"
          >
            获取验证码
          </div>
          <div class="button-message" v-show="!state.show">
            {{ state.count }}s
          </div>
        </div>
      </div>
      <div class="username-container">
        <van-field
          v-model="state.username"
          placeholder="请输入用户名"
          center
          maxlength="32"
        >
          <template #left-icon>
            <v-iconFontSymbol
              :icon="'iconpeople'"
              :style="{ 'font-size': '0.15rem' }"
            />
          </template>
        </van-field>
        <div class="username-tips">
          6-32位由中英文、数字或下划线组合，不能为纯数字
        </div>
      </div>
      <div class="password-container">
        <van-field
          v-model="state.password"
          :type="state.password_icon ? 'password' : 'text'"
          placeholder="请输入你的密码"
          center
          maxlength="20"
          @click-right-icon="clickRight('password', state.password_icon)"
        >
          <template #left-icon>
            <v-iconFontSymbol
              :icon="'iconlock'"
              :style="{ 'font-size': '0.15rem' }"
            />
          </template>
          <template #right-icon>
            <v-iconFontSymbol
              :icon="state.password_icon ? 'iconhide' : 'icondisplay'"
              :style="{ 'font-size': '0.18rem' }"
            />
          </template>
        </van-field>
        <div class="password-tips">
          6-20位，字母、数字、符号至少包含两种，且不能与用户名相同
        </div>
      </div>

      <div class="last-field">
        <van-field
          v-model="state.password2"
          :type="state.password2_icon ? 'password' : 'text'"
          placeholder="请再次输入登录密码"
          center
          maxlength="20"
          @click-right-icon="clickRight('password2', state.password2_icon)"
        >
          <template #left-icon>
            <v-iconFontSymbol
              :icon="'iconlock'"
              :style="{ 'font-size': '0.15rem' }"
            />
          </template>
          <template #right-icon>
            <v-iconFontSymbol
              :icon="state.password2_icon ? 'iconhide' : 'icondisplay'"
              :style="{ 'font-size': '0.18rem' }"
            />
          </template>
        </van-field>
      </div>
    </div>

    <div class="checkbox-container">
      <van-checkbox
        v-model="state.checked"
        label-disabled
        icon-size="18"
        @change="changeClick"
      >
        同意
      </van-checkbox>
      <div class="othe-button">
        <span> 《 </span
        ><span class="fuwuxieyi" @click="toUserAgreement"
          >基因云用户服务协议</span
        ><span>》</span>
      </div>
    </div>

    <div class="button-container">
      <div @click="toHome">注册</div>
    </div>

    <div class="other-container" @click="toLogin">已有账号 直接登入</div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";

import { uuid } from "@/tools/common";
import userInfoApi from "@/api/userInfo";

export default {
  name: "Register",
  components: {
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const checked = ref(true);
    const state = reactive({
      client_type: "mobile",
      union_id: "",
      telephone: "",
      mess_code: "",
      username: "",
      password: "",
      password2: "",
      agree_deal: true,
      user_uuid: "",
      show: true,
      count: "",
      timer: null,
      password_icon: true,
      password2_icon: true,
    });

    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const toRegister = async (params) => {
      const res = await proxy.$request.handleFetchPostJson(
        params.inviter ? userInfoApi.membership_register : userInfoApi.register,
        // "user/register/",
        params
      );
      // getUserInfo();
      proxy.$cookie.setCookie("tokenid", res.token, 25, "/");
      proxy.$store.dispatch("getUserInfoAction");
      router.replace({
        name: "home",
      });
    };

    const getMessage = async (telephone) => {
      await proxy.$request.handleFetchGet(
        `${userInfoApi.mess_code}${telephone}`
      );
      Toast("验证码发送成功");
      const TIME_COUNT = 60;
      if (!state.timer) {
        state.count = TIME_COUNT;
        state.show = false;
        state.timer = setInterval(() => {
          if (state.count > 0 && state.count <= TIME_COUNT) {
            state.count--;
          } else {
            state.show = true;
            clearInterval(state.timer);
            state.timer = null;
          }
        }, 1000);
      }
    };

    const checkPhone = async (telephone) => {
      const res = await proxy.$request.handleFetchGet(
        `${userInfoApi.telunique}${telephone}`
      );
      if (res.tel_count == 2) {
        Toast("该号码已被注册");
      } else {
        getMessage(telephone);
      }
    };

    const checkUser = async (username) => {
      const res = await proxy.$request.handleFetchGet(
        `${userInfoApi.userunique}${username}`
      );
      if (res.tel_count == 2) {
        Toast("该用户名已被注册");
      } else {
        let params = {
          telephone: state.telephone,
          mess_code: state.mess_code,
          username: state.username,
          password: state.password,
          // password2: state.password2,
          // agree_deal: state.agree_deal,
          user_uuid: uuid(),
          client_type: "mobile",
          union_id: proxy.$cookie.getCookie("unionid"),
        };
        let inviteCode = proxy.$cookie.getCookie("userInfo")
          ? JSON.parse(proxy.$cookie.getCookie("userInfo"))
          : {};

        if (inviteCode.user_info) {
          params.inviter = inviteCode.user_info;
        } else {
          params.password2 = state.password2;
          params.agree_deal = state.agree_deal;
        }
        toRegister(params);
      }
    };

    const toLogin = () => {
      router.push({
        name: "login",
      });
    };

    const toHome = () => {
      if (state.telephone == "") {
        Toast("请输入手机号");
        return;
      }
      if (state.mess_code == "") {
        Toast("请输入验证码");
        return;
      }
      if (state.username.length < 6 || state.username.length > 32) {
        Toast("用户名长度必须为6-32位");
        return;
      }
      let patrn = /^[0-9]*$/;
      if (patrn.test(state.username)) {
        Toast("用户名不能为纯数字");
        return;
      }
      let passwordLenRe =
        /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![`~!@#$%^&*()_+<>?:"{},./;'[\]]+$)(?!([^(0-9a-zA-Z`~!@#$%^&*()_+<>?:"{},./;'[\])])+$)^.{6,20}$/;
      if (!passwordLenRe.test(state.password)) {
        Toast("输入的密码不符合要求");
        return;
      }
      if (state.password == state.username) {
        Toast("密码不能与用户名相同");
        return;
      }
      if (state.password2 !== state.password) {
        Toast("重新输入的密码必须与第一次输入的密码相同");
      }
      if (!state.agree_deal) {
        Toast("请勾选基因云用户服务协议");
        return;
      }
      checkUser(state.username);
    };

    const getMessageCode = () => {
      if (state.telephone == "") {
        Toast("请输入手机号");
        return false;
      }
      checkPhone(state.telephone);
    };

    const toUserAgreement = () => {
      router.push({
        name: "userAgreement",
      });
    };

    const changeClick = (e) => {
      state.agree_deal = e;
    };

    const clickRight = (value, type) => {
      if (value == "password") {
        state.password_icon = !type;
      } else {
        state.password2_icon = !type;
      }
    };

    return {
      state,
      checked,
      toLogin,
      toHome,
      toUserAgreement,
      getMessageCode,
      changeClick,
      clickRight,
    };
  },
};
</script>
